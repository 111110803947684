module.exports = [{
      plugin: require('/Users/chris/Programming/websites/armorflex/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-31527228-4","head":false,"anonymize":false,"exclude":["/preview/**"]},
    },{
      plugin: require('/Users/chris/Programming/websites/armorflex/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/chris/Programming/websites/armorflex/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
